(function () {
    angular.module('informaApp')
        .service('TrendsViewTooltipGenerator', TrendsViewTooltipGenerator);

    function TrendsViewTooltipGenerator() {
        return {
            generate(point) {
                const values = {
                    pos: generateTooltipValueRow(point, x => x.pos),
                    loa: generateTooltipValueRow(point, x => x.loa),
                    duration: generateTooltipValueRow(point, x => x.duration),
                    numberOfTransitions: generateTooltipValueRow(point, x => x.numberOfTransitions)
                };

                return `
                    <p class="caption" style="font-weight: 700">PoS: ${values.pos}</p>
                    <p class="caption">LoA: ${values.loa}</p>
                    <p class="caption">Duration of <br/> adv. programs: ${values.duration}</p>
                    <p class="caption">Nº of transitions: ${values.numberOfTransitions}</p>
                `;
            }
        }
    }

    function generateTooltipValueRow(point, getValue) {
        return  `<span style="color: ${point.color}">${getValue(point)}</span>`
    }
})();
